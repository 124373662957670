let origin = window.ORIGIN;
// 随手拍上传接口
const uploadingSnapshotUrl = `${origin}/gateway/hc-serve/miniapi/snapshot/uploadingSnapshot`;
// 随手拍详情
const snapshotDetailsUrl = `${origin}/gateway/hc-serve/miniapi/snapshot/snapshotDetails`;
// 满意度评价
const satisfactionEvaluationUrl = `${origin}/gateway/hc-serve/miniapi/snapshot/satisfactionEvaluation`;
// 随手拍列表
const snapshotListUrl = `${origin}/gateway/hc-serve/miniapi/snapshot/snapshotList`;
// 事件类型列表
const incidentTypeListUrl = `${origin}/gateway/hc-serve/manageapi/snapshot/incidentTypeList`;
// 获取当前使用房号
const userAssetListUrl = `${origin}/gateway/hc-mini/user/mini/user-asset-list`;
//获取区级联动接口
const areaLinkUrl = `${origin}/gateway/hc-space/space/getSpaceLinkList`;
// 工单模块权限
const workOtherJurisdictionUrl = `${origin}/gateway/hc-serve/miniapi/snapshot/workOtherJurisdiction`;
export {
  uploadingSnapshotUrl,
  snapshotDetailsUrl,
  satisfactionEvaluationUrl,
  snapshotListUrl,
  incidentTypeListUrl,
  userAssetListUrl,
  areaLinkUrl,
  workOtherJurisdictionUrl,
};
